.navigation{
    background: #0c0c0c;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 3.5rem;
    width: 100%;
    font-weight: 600;

    @media only screen and (min-device-width: 320px) and (max-device-width: 480px){
        position: fixed;
        bottom: 0%;
    }

    .checkbox{
        display: none;
    }
    .label{
        height: 13px;
        width: 30px;
        background: $color-4;
        color: $color-3;
        padding: 5px;
        border-radius: 50px ;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        transform: scale(1.2);
        font-size: 12px;
    }
    .label .ball{
        background: $color-3;
        border-radius: 50%;
        height: 13px;
        width: 13px;
        position: absolute;
        transition: transform 0.4s cubic-bezier(.45, -1.12, .35, 1.78);
    }
    .checkbox:checked + .label .ball{
        transform: translateX(17px);
    }
}
.linknav{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.nav-active {
    color: $color-1;
    border-bottom: 2px solid $color-1;
}
.navlink{
    margin-bottom: 2px;

    @media screen and (max-width: 600px){
            font-size: 10px;
            margin-bottom: 1px;
    }
}
.navmobil{
    font-size: 12px;
    display: none;

    @media screen and (max-width: 600px){
        font-size: 16px;
        display: block;
    }
}
ul{
    display: flex;
    justify-content: space-around;
}