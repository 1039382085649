.contact{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;

    .container_form{
        background: #ffffffb2;
        max-width: 40rem;
        width: 100%;
        margin: 2rem;
        padding: 1rem;
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        .form {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .message{
                width: 100%;

                .nom_prenom{
                    display: flex;
                }

                .input{
                    width: 100%;
                    display: flex;
                    justify-content: center;


                    input{
                        width: 100%;
                        padding: 0.5rem;
                        margin: 0.5rem;
                        background: none;
                        border: none;
                        font-size: $font-size-4;
                    }

                    textarea {
                        width: 100%;
                        padding: 0.5rem;
                        height: 5rem;
                        margin: 0.5rem;
                        background: none;
                        border: none;
                        font-family: $font-1;
                        font-size: $font-size-4;
                    }
                }
            }

            .send{
                width: 100%;
                max-width: 15rem;
                padding: 0.5rem;
                border-radius: 0.5rem;
                background: #000000d7;
                border: none;
                color: $color-4;
            }
        }
    }
}