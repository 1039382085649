.container_langages{
        max-width: 15rem;
        min-width: 15rem;
        margin: 1rem;
        flex: 1;
    h1{
        margin-bottom: 2rem;
        text-align: center;
    }

    .box_langages{
        padding: 1rem;
        background: #ffffffb2;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        color: $color-3;
        border-radius: 10px;

        .box_langage{
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin: 1rem;
            width: 4rem;
            height: 4rem;
        

            .langage{
                font-size: 4rem;
            }

            .fa-php{
                font-size: 3rem;
            }
        }
    }
}