.back_portfolio{
    background: url(https://i.pinimg.com/originals/b7/c5/0d/b7c50d2862a9e7155049856c1d4ffe4e.png);
    background-size: cover;
}
.container_portfolio{
    margin: 1rem 2rem;
}
.radiosDisplay{
    background: $color-3;
    padding: 0.5rem;
    border-radius: 20px;
}
.radios {
    color: $color-4;
    font-size: 1.5rem;
    padding: 0 1rem;
    text-align: center;

    input[type="radio"]{
        display: none;

      &:before {
        cursor: pointer;
        transition: 0.3s;
      }
      &:after {
        background: $color-1;
      }
    }
      input:checked + label {
        color: $color-1;
      }
}
.projets{
    flex: 1;
    display: flex;
    flex-wrap: wrap;
}

.projet{
    background: $color-3;
    margin: 1rem;
    padding: 0.5rem;
    width: 15rem;

    @media only screen and (min-device-width: 320px) and (max-device-width: 480px){
        width: 100%;
    }
}
.name_projet{
    margin-bottom: 0.5rem;
    font-size: 20px;
}


.img_projet{
    width: 100%;
}