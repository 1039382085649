.container_diplome{
    margin: 1rem;
    flex: 2;
    min-width: 30rem;

    h1{
        text-align: center;
    }

    .formation-diplome{
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 20rem;
        overflow-y: scroll;
        scrollbar-width: none;
        overflow-x:hidden;
        padding: 0 2rem;
    }

    .formation-diplome::-webkit-scrollbar{
        background-color: rgba(111, 111, 111, 0.202);
        border-radius: 0.5rem;
    }

    .formation-diplome::-webkit-scrollbar-thumb{
        background-color: #ffffffb2;
        border-radius: 0.5rem;
    }

    .formation{
        padding: 1rem;
        border-radius: 10px;
        background: #ffffffb2;
        color: $color-3;
        width: 100%;
    }

    .formation-1{
        margin-bottom: 2rem;
    }

    .formation-2{
        margin-bottom: 2rem;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px){

    .container_diplome{
        min-width: 100%;

    
        .formation-diplome{
            display: flex;
            overflow-y: auto;
            height: 100%;
        }
    }
}
