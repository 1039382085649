@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600&display=swap');

$font-1: 'Quicksand', sans-serif;

$font-size-1: min(35px, max(3.75vw, 30px));
$font-size-2: min(25px, max(3.75vw, 20px));
$font-size-3: min(20px, max(3.75vw, 15px));
$font-size-4: 15px;


$color-1: #ef233c;
$color-2: #1e1b18;
$color-3: #161a1d;
$color-4: #faf9f9;


@mixin verticalcenter {
    display: flex;
    justify-content: center;
}


*{
    margin: 0;
    padding: 0;
    transition: 1s;
    scroll-behavior: smooth;
    cursor: none;
}

body{
    background: #010101;
    color: $color-4;
    overflow-x: hidden;
    font-family: $font-1;
    text-align: justify;
}

li {
    list-style-type: none;
    color: $color-4;
}

a{
    text-decoration: none;
    color: $color-4;
}

h1{
    font-size: $font-size-1;
    margin-bottom: 0.5rem;
}

h2{
    font-size: $font-size-2;
}

h3{
    font-size: $font-size-4;
}

p{
    font-size: $font-size-3;
}

.cursor{
    width: 3rem;
    height: 3rem;
    background: #ffffff;
    border-radius: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    pointer-events: none;
    transition: none;
    z-index: 2;
    mix-blend-mode: exclusion;

    .pointer{
        width: 0.2rem;
        height: 0.2rem;
        top: 50%;
        left: 50%;
        background: $color-3;
        border-radius: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
    }
}


.back_gradient{
    background: rgba(5, 5, 5, 0.873);
    min-height: 100vh;
}