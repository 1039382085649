.back_competence{
    background: url(https://wallpaperaccess.com/full/38834.jpg) 50% 50% no-repeat fixed;
    background-size: cover;
    height: 100vh;
}

.container_competence{
    margin: 1rem;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}