.back{
    background: url(https://wallpaperaccess.com/full/12694.jpg) 50% 50% no-repeat fixed;
    background-size: cover;
    min-height: 100vh;
}

.container_accueil {
    display: flex;
    flex-wrap: wrap;
    margin: 4rem 1rem 1rem 1rem;
}
.container_a_propo{
    flex: 1;
    max-width: 40rem;
    min-width: 15rem;
    margin: 2rem 1rem;
}
.container_profil{
    flex: 1;
    display: flex;
    justify-content: center;
    width: 100%;
}
.profil{
    background: #1a1a1ab1;
    margin: 2rem 1rem;
    padding: 2rem;
    min-width: 10rem;
}
.list_profil{
    display: flex;
    flex-direction: column;
}
.info{
    font-size: 17px;
    margin: 0.5rem 0;
}
.nom{
    margin-bottom: 1rem;
}